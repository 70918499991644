import { Box } from '@core/component';
import { themeContainerMaxWidth } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { BackgroundColorType, ColorProps, EColor, MediaQuerySize } from '@core/type';
import { GetSanityComponentHandler, SanityWidget } from '@core/type/sanity';
import { renderSanitySections } from '@core/util';

import {
  WidgetBackgroundColorType,
  WidgetContainerComponentEntity,
  WidgetContainerProps,
} from './interface-widget-container';

const getWidgetBackgroundColorProps = (
  backgroundColor: WidgetBackgroundColorType,
): ColorProps<BackgroundColorType> => {
  switch (backgroundColor) {
    case EColor.BLUE:
      return EColor.INFO;
    case EColor.GRAY:
      return { semanticType: EColor.SECONDARY, variant: EColor.LIGHT };
    case EColor.TRANSPARENT:
      return backgroundColor;
    case EColor.WHITE:
      return { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT };
    default:
      return null;
  }
};

export const WidgetContainer = ({
  sections,
  borderRadius,
  horizontalPaddings,
  verticalPaddings,
  horizontalMobilePaddings,
  verticalMobilePaddings,
  backgroundColor,
  maxWidth,
  hasBorder,
  getSanityComponent,
  pageId,
}: WidgetContainerProps) => {
  const { isMobile } = useContextMediaQuery();

  const padding = isMobile
    ? `${verticalMobilePaddings || 0}px ${horizontalMobilePaddings || 0}px`
    : `${verticalPaddings || 0}px ${horizontalPaddings || 0}px`;

  if (!sections.length) {
    return null;
  }

  return (
    <Box
      width={'100%'}
      maxWidth={themeContainerMaxWidth[maxWidth]}
      padding={padding}
      backgroundColor={getWidgetBackgroundColorProps(backgroundColor)}
      margin={'0 auto'}
      borderRadius={borderRadius}
      borderColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
      borderWidth={hasBorder ? '1px' : undefined}
    >
      {renderSanitySections(sections, pageId, getSanityComponent)}
    </Box>
  );
};

export const getWidgetContainer = (
  section: SanityWidget,
  pageId: string,
  getSanityComponent: GetSanityComponentHandler,
) => {
  const props = section as WidgetContainerComponentEntity;

  return (
    <WidgetContainer
      backgroundColor={props?.backgroundColor as WidgetBackgroundColorType}
      borderRadius={props?.borderRadius}
      pageId={pageId}
      hasBorder={props?.hasBorder}
      sections={props?.sections || []}
      horizontalPaddings={props?.horizontalPaddings}
      verticalPaddings={props?.verticalPaddings}
      horizontalMobilePaddings={props?.horizontalMobilePaddings}
      verticalMobilePaddings={props?.verticalMobilePaddings}
      maxWidth={props?.maxWidth as MediaQuerySize}
      getSanityComponent={getSanityComponent}
    />
  );
};
